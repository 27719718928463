@keyframes item-in-right {
    from {
        left: 50%;
    }
    to {
        left: 0;
    }
}

@keyframes item-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes item-done-fade-in {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.5;
    }
}

$planner-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
$planner-corner: 6px;
$planner-corner-lg: 16px;

.planner-daily,
.planner-weekly,
.planner-monthly,
.planner-yearly {
    width: 360px;
    user-select: none;
    position: relative;
    text-align: left;
    margin: 0 auto;

    .item.-done {
        animation: item-done-fade-in 0.5s linear 1s forwards;

        .title {
            position: relative;

            &::before {
                content: "";
                height: 1px;
                display: block;
                position: absolute;
                background-color: $color-planner-font;
                left: 100%;
                right: 0;
                top: 50%;
                animation: item-in-right 0.8s linear 0.5s forwards;
            }
        }
    }
}

.planner-daily {
    > .item {
        background-color: white;
        border-radius: $planner-corner;
        margin-bottom: 16px;
        color: $color-planner-font;
        box-shadow: $planner-shadow;
        display: flex;
        padding: 8px;
        font-size: 14px;
        align-items: center;
        position: relative;

        .icon {
            font-size: 16px;
        }

        .title {
            margin: 0 16px;
        }

        .time {
            font-size: 12px;
            flex-grow: 1;
            color: $color-planner-accent;
            text-align: right;
        }
    }
}

.planner-yearly,
.planner-monthly,
.planner-weekly {
    background-color: white;
    border-radius: $planner-corner-lg;
    color: $color-planner-font;
    box-shadow: $planner-shadow;
    display: flex;
    flex-wrap: wrap;

    > .cell-item-container {
        height: 0;
        color: $color-planner-accent;
        font-size: 11px;
        overflow: hidden;
        position: relative;

        > .cell-item {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .cell-title {
            text-align: center;
            margin: 8px 0 4px;
        }

        .item {
            color: $color-planner-font;
            display: flex;
            align-items: center;
            margin: 0 0 2px;
            padding: 0 4px;

            &:not(.-done) {
                opacity: 0;
            }

            .icon {
                font-size: 14px;
            }

            .title {
                margin: 0 4px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    &.-animate {
        .item {
            &:not(.-done) {
                &:nth-child(1) {
                    animation: item-fade-in 1s ease-in 0.1s forwards;
                }
                &:nth-child(2) {
                    animation: item-fade-in 1s ease-in 0.4s forwards;
                }
                &:nth-child(3) {
                    animation: item-fade-in 1s ease-in 0.3s forwards;
                }
                &:nth-child(4) {
                    animation: item-fade-in 1s ease-in 0.2s forwards;
                }
            }
        }
    }
}

.planner-weekly {
    > .cell-item-container {
        width: calc(50% - 1px);
        padding-top: calc(50% - 32px);

        &:not(:last-child) {
            border-bottom: 1px solid $color-border;
        }

        &:nth-child(odd) {
            border-right: 1px solid $color-border;
        }
    }
}

.planner-yearly {
    > .cell-item-container {
        width: calc((100% / 3) - 1px);
        padding-top: calc((100% / 3) - 1px);

        &:not(:last-child):not(:nth-last-child(2)):not(:nth-last-child(3)) {
            border-bottom: 1px solid $color-border;
        }

        &:not(:nth-child(3n)) {
            border-right: 1px solid $color-border;
        }
    }
}

.planner-monthly {
    margin: 0 auto 16px;

    > .cell-item-container {
        width: calc((100% / 7) - 1px);
        padding-top: calc((100% / 7) - 1px);

        &.-day {
            padding-top: 32px !important;
            font-weight: bold;
        }

        &:not(:last-child):not(:nth-last-child(2)):not(:nth-last-child(3)):not(:nth-last-child(4)):not(:nth-last-child(5)):not(:nth-last-child(6)):not(:nth-last-child(7)) {
            border-bottom: 1px solid $color-border;
        }

        &:not(:nth-child(7n)) {
            border-right: 1px solid $color-border;
        }

        .cell-title.-date {
            margin: 0;
            text-align: right;
            padding: 2px 4px 0 0;
        }

        .item {
            .icon {
                font-size: 10px;
            }

            .title {
                font-size: 8px;
                margin: 2px;
            }
        }
    }
}

@media screen and (max-width: $width-phone) {
    .planner-daily,
    .planner-weekly,
    .planner-monthly,
    .planner-yearly {
        width: 300px;
        transform: scale(0.9);
    }
}

@media screen and (min-width: $width-tablet) {
    .planner-daily {
        &.-animate {
            > .item:not(.-done) {
                &:nth-child(2) {
                    animation: item-in-right 0.5s ease-out 0.1s forwards;
                }
                &:nth-child(3) {
                    animation: item-in-right 0.5s ease-out 0.2s forwards;
                }
                &:nth-child(4) {
                    animation: item-in-right 0.5s ease-out 0.3s forwards;
                }
                &:nth-child(5) {
                    animation: item-in-right 0.5s ease-out 0.4s forwards;
                }
                &:nth-child(6) {
                    animation: item-in-right 0.5s ease-out 0.5s forwards;
                }
                &:nth-child(7) {
                    animation: item-in-right 0.5s ease-out 0.6s forwards;
                }
            }
        }
    }
}