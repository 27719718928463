@keyframes blink {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.account-management {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 640px;
  flex-direction: column;
  position: relative;
  
  .card {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    max-width: 400px;
    margin: 0 auto;
    padding: 16px;
    font-size: 14px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 16px;
  }

  p,
  form {
    text-align: left;
  }

  label {
    font-weight: bold;
  }

  button {
    height: 48px;
    margin: 8px 0 0;
  }

  .app-name {
    margin: 0 0 36px;

    p {
      text-align: center;
      margin: 0;
    }
  }

  .loading-view {
    p {
      letter-spacing: 4px;
      text-transform: uppercase;
      font-size: 16px;
      animation: item-fade-in 1s ease-in;
      animation-iteration-count: infinite;
    }
  }

  .animation-background {
    background-color: rgba($color-blue, 0.25);
    border-radius: 24px;
    position: relative;
    max-width: 400px;
    margin: 0 auto;
  }

  .invalid-view,
  .loading-view,
  .success-view {
    animation: item-fade-in 1s linear forwards;

    .text {
      margin: 24px 0 0;
    }
    
    p {
      text-align: center;
      margin: 0 0 16px;
    }
  }

  .invalid-view {
    .animation-background {
      overflow: hidden;
      > div {
        transform: scale(1.5);
      }
    } 
  }
}

@media screen and (max-width: $width-tablet) {
  .account-management {
    .wrapper {
      padding: 24px;
    }
  }
}