.wrapper {
  max-width: $width-max;
  margin: 0 auto;
}

.text-container {
  max-width: 640px;
  margin: 0 auto;
}

.bg-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
