.hero {
  height: 680px;
  background-image: url('../../public/img/hero-background.jpeg');

  > .wrapper {
    display: flex;
    justify-content: center;
    height: 100%;

    > div {
      width: 50%;
      min-width: 0;
      height: 100%;
      box-sizing: border-box;
    }
  }

  .picture-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
      display: block;
      margin-right: 170px;
    }
  }

  .text-container {
    display: flex;
    height: 100%;
    width: calc(50% - 170px);
    min-width: 400px;
    flex-direction: column;
    padding: 80px;
    color: white;
    justify-content: center;

    h1 {
      margin: 0;
      font-size: 34px;
    }

    p {
      margin: 20px 0;
      width: 400px;
      line-height: 1.5em;
      font-size: 18px;
    }

    img {
      height: 60px;
    }
  }
}

.counter {
  background-image: url("../../public/img/counter-background.jpeg");

  section {
    padding: 80px 0;
  }
}

.counter-list {
  $box-size: 128px;

  display: flex;
  align-items: center;
  justify-content: center;

  > .item {
    background-color: white;
    padding: 20px;
    width: $box-size;
    height: $box-size;
    margin: 40px 40px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);

    h3 {
      font-size: 36px;
      margin: 0;
    }

    p {
      text-transform: uppercase;
      margin: 8px 0 0;
      font-size: smaller;
    }
  }
}

.planner-view {
  padding: 80px 0;

  &.-yearly {
    background-image: url("../../public/img/planner-background-1.jpeg");
  }

  &.-weekly {
    background-image: url("../../public/img/planner-background-2.jpeg");
  }

  > .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    > div {
      width: 50%;
      min-width: 0;
      height: 100%;
      box-sizing: border-box;
    }
  }

  .text-container {
    &:first-child {
      padding-right: 80px;
    }

    &:last-child {
      padding-left: 80px;
    }
  }

  img {
    width: 400px;
    height: auto;
  }
}

.remark {
  font-size: smaller !important;
}

@media screen and (max-width: $width-tablet) {
  .hero {
    height: auto;
    background-image: url('../../public/img/hero-background-mobile.jpeg');

    > .wrapper {
      flex-direction: column-reverse;

      > div {
        width: 100%;
        height: auto;
      }
    }

    .picture-container {
      flex-direction: column;
      align-items: center;
      overflow: hidden;

      img {
        margin: -70px 0 0;
        width: calc(100% + 360px);
        max-width: 600px;
      }
    }

    .text-container {
      height: auto;
      min-width: 0;
      padding: 40px 40px 0;
      text-align: center;

      p {
        width: unset;
        font-size: 16px;
      }
    }
  }

  .counter {
    background-image: url("../../public/img/counter-background-mobile.jpeg");
    background-position: center top;

    section {
      padding: 40px 0;
    }
  }

  .planner-view {
    padding: 40px;

    &.-yearly,
    &.-weekly {
      background-image: url("../../public/img/planner-background-1.jpeg");
      background-position: center;
    }

    &.-monthly,
    &.-daily {
      > .wrapper {
        flex-direction: column-reverse;
      }
    }

    > .wrapper {
      flex-direction: column;

      > div {
        width: 100%;
      }
    }

    .text-container {
      padding: 0 !important;
      margin: 0 0 20px;
    }

    .image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }
}

@media screen and (max-width: $width-phone) {
  .counter-list {
    $box-size: 96px;
    flex-direction: column;
    margin: 0 0 40px;

    > .item {
      margin: 20px 0;
      width: $box-size;
      height: $box-size;
      padding: 16px;

      h3 {
        letter-spacing: normal;
      }
    }
  }
}