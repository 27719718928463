@import "sizes";
@import "colors";
@import "layout";
@import "home";
@import "planner";
@import "account";

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500&family=Mali:wght@500&family=Poppins:wght@400;600&display=swap');

body {
    margin: 0;
    font-family: "Poppins", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $color-background;
    color: $color-font;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
.app-name {
    font-family: "Mali", sans-serif;
}

h1 {
    font-size: 42px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 28px;
}

section {
    padding: 120px 0;
    text-align: center;
    h1,
    h2,
    h3 {
        font-family: "Barlow Condensed", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    }

    h1,
    h2 {
        margin: 0 0 40px;
    }

    h3 {
        margin: 0 0 20px;
    }
}

footer {
    background-color: $color-background-dark;
    padding: 16px;
    color: white;
    font-size: smaller;
    text-align: center;

    p {
        margin: 0;
    }
}

.app-name {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
}

@media screen and (max-width: $width-tablet) {
    h1 {
        font-size: 36px;
    }
    
    h2 {
        font-size: 24px;
    }
    
    h3 {
        font-size: 22px;
    }

    body {
        font-size: 14px;
    }

    section {
        padding: 40px;

        h1,
        h2 {
            margin: 0 0 20px;
        }
    
        h3 {
            margin: 0 0 16px;
        }
    }
}